<template>
  <CommonButton
    v-if="!emailSend"
    bg="bg-blue"
    text="text-white"
    border="border-none"
    @click="sendActivationEmail"
  >
    {{ $t('ResendActivationMail') }}
  </CommonButton>
  <div v-else class="h-9 leading-9">
    {{ $t('EmailSendt') }}
  </div>
</template>

<script setup lang="ts">
const userStore = useUserStore()

const emailSend = ref(false)

async function sendActivationEmail() {
  const response = await userStore.resendActivationMail()
  if (response?.type === 'success') {
    emailSend.value = true
  }
}
</script>
